@font-face {
    font-family: "EncodeSans";
    src: url("./EncodeSans/EncodeSans-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "EncodeSans";
    font-weight: 100;
    src: url("./EncodeSans/EncodeSans-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: "EncodeSans";
    font-weight: bold;
    src: url("./EncodeSans/EncodeSans-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "EncodeSans";
    font-weight: 600;
    src: url("./EncodeSans/EncodeSans-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "EncodeSans";
    font-weight: 900;
    src: url("./EncodeSans/EncodeSans-ExtraBold.ttf") format("truetype");
  }

@font-face {
    font-family: "Sora";
    src: url("./Sora/Sora-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Sora";
    font-weight: 100;
    src: url("./Sora/Sora-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: "Sora";
    font-weight: bold;
    src: url("./Sora/Sora-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Sora";
    font-weight: 600;
    src: url("./Sora/Sora-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Sora";
    font-weight: 900;
    src: url("./Sora/Sora-ExtraBold.ttf") format("truetype");
  }



